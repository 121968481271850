import React from "react";
import { useFormik } from "formik";

import { Input } from "./Input";
import { validate } from "../utils/validate";
import { redirect, getRedirectUrl } from "../utils/redirect";

export const homePlusRedirect = formData => {
  const url = getRedirectUrl(formData, "https://www.signup.asurion.com/home-plus/asurion/affiliate/personal-info")
  return redirect(url);
}
export const appliancePlusRedirect = formData => {
  const url = getRedirectUrl(formData, "https://www.signup.asurion.com/appliance-plus/asurion/affiliate/personal-info")
  return redirect(url);  
}
export const UBIFForm = () => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    isSubmitting,
    handleSubmit
  } = useFormik({
    initialValues: {
      referrerCode: "ubif",
      agentId: "",
      storeCode: null,
      vendorData: null
    },
    validate,
  });

  return (
    <>
      <h1 className="title">Appliance+</h1>
      <p className="subtitle">UBIF Tech Enrollment</p>
      <form>
        <Input
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.referrerCode}
          label="Referrer Code"
          name="referrerCode"
          icon="tags"
          errorMessage={errors.referrerCode}
          touched={touched.referrerCode}
        />

        <Input
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.agentId}
          label="Agent Id"
          name="agentId"
          icon="user-shield"
          errorMessage={errors.agentId}
          touched={touched.agentId}
        />
        <Input
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.storeCode}
          label="Store Code"
          name="storeCode"
          icon="store"
          errorMessage={errors.storeCode}
          touched={touched.storeCode}
        />
        <div className="field is-grouped" style={{display:"flex",flexDirection:"column"}}>
          {/* <div className="control" style={{marginBottom: "1em"}}>
            <button
              className="button is-link"
              disabled={isSubmitting}
              type="submit"
              onClick={() =>{
                homePlusRedirect(values)
                handleSubmit()
              }}
            >
              Continue to Home+ Enrollment
            </button>
          
          </div> */}
          <div className="control">
            <button
              className="button is-link"
              disabled={isSubmitting}
              type="submit"
              onClick={() => {
                appliancePlusRedirect(values)
                handleSubmit()
              }}
            >
              Continue to Appliance+ Enrollment
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default UBIFForm;
