import React from 'react';
import { Icon } from './Icon';
import '../index.css';
import { copyTextToClipboard } from '../utils/copyToClipboard';
export const UrlOutput = ({
    value,
}) => {
    const [copied, setIsCopied] = React.useState(false)
    // onClick handler function for the copy button
    const handleCopyClick = () => {
        // Asynchronously call copyTextToClipboard
        copyTextToClipboard(value)
        .then(() => {
            // If successful, update the isCopied state value
            setIsCopied(true);
            setTimeout(() => {
            setIsCopied(false);
            }, 1500);
        })
        .catch((err) => {
            console.log(err);
        });
    }

    return (
        <>
            <div className="url-output-box" style={{border: copied ? '1px solid green': '1px solid #dbdbdb' }}>
            <div style={{margin: '1rem'}} title="Click here to copy" onClick={() => handleCopyClick()}>
                    <Icon title="Click here to copy" className="fa-link" />
                </div>
            <div id="url-output">{value}</div>
            </div>
            <div>{copied? 'Copied' : ''}</div>
        </>
    );
  };
  