export const validate = values => {
  const errors = {};

  if (!values.referrerCode) {
    errors.referrerCode = "Referrer Code is required.";
  }

  if (!values.agentId) {
    errors.agentId = "Agent ID is required.";
  }
  

  return errors;
};
