import React from "react";
import { useFormik } from "formik";

import { Input } from "./Input";
import { validate } from "../utils/validate";
import { redirect, getRedirectUrl } from "../utils/redirect";

export const onSubmit = formData => {
  const url = getRedirectUrl(formData);
  redirect(url);
};

export const AffiliateLinkForm = () => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting
  } = useFormik({
    initialValues: {
      referrerCode: "",
      agentId: "",
      storeCode: null,
      vendorData: null
    },
    validate,
    onSubmit
  });

  return (
    <>
      <h1 className="title">Home+ Affiliates</h1>
      <p className="subtitle">Tech Enrollment</p>
      <form onSubmit={handleSubmit}>
        <Input
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.referrerCode}
          label="Referrer Code"
          name="referrerCode"
          icon="tags"
          errorMessage={errors.referrerCode}
          touched={touched.referrerCode}
          />

        <Input
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.agentId}
          label="Agent Id"
          name="agentId"
          icon="user-shield"
          errorMessage={errors.agentId}
          touched={touched.agentId}
          />
        <Input
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.storeCode}
          label="Store Code"
          name="storeCode"
          icon="store"
          errorMessage={errors.storeCode}
          touched={touched.storeCode}
          />
        <Input
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.vendorData}
          label="Vendor Data"
          name="vendorData"
          icon="handshake"
          errorMessage={errors.vendorData}
          touched={touched.vendorData}
          />

        <div className="field is-grouped">
          <div className="control">
            <button
              className="button is-link"
              type="submit"
              disabled={isSubmitting}
              >
              Continue to Customer Enrollment
            </button>
          </div>
        </div>
      </form>
    </>
  );
};

export default AffiliateLinkForm;
