import qs from 'query-string';

export const getRedirectUrl = (
  { referrerCode, agentId, storeCode = null, vendorData = null, utm_campaign = null },
  url = "https://my-asurion.com/registration/personaldetails"
) =>
  qs.stringifyUrl(
    {
      url: url,
      query: {
        RCO: referrerCode,
        a_id: agentId,
        s_c: storeCode,
        v_data: vendorData,
        utm_source: referrerCode,
        utm_medium: "affiliate",
        utm_campaign: utm_campaign ?? "agentenrollment",
        utm_content: Date.now(),
      },
    },
    { skipNull: true }
  );

export const redirect = (url) => {
  window.location.href = url;
};
