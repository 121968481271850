import React from "react";
import { useFormik } from "formik";

import { Input } from "./Input";
import { Checkbox } from "./Checkbox";
import PreEnrollmentInformation from "./PreEnrollmentInformation";
import { validate } from "../utils/validate";
import { redirect, getRedirectUrl } from "../utils/redirect";

export const onSubmit = (formData) => {
  const url = getRedirectUrl(
    formData,
    "https://www.signup.asurion.com/home-plus/asurion/affiliate/personal-info"
  );
  redirect(url);
};

export const UnifiedFieldServicesForm = () => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
  } = useFormik({
    initialValues: {
      referrerCode: "ufs",
      agentId: "",
      storeCode: null,
      vendorData: null,
      preenroll: false,
    },
    validate,
    onSubmit,
  });

  return (
    <>
      <h1 className="title">Home+ Affiliates</h1>
      <p className="subtitle">Tech Enrollment</p>
      <form onSubmit={handleSubmit}>
        <Input
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.agentId}
          label="Tech Id"
          name="agentId"
          icon="user-shield"
          errorMessage={errors.agentId}
          touched={touched.agentId}
          />

        <PreEnrollmentInformation />

        <Checkbox
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.preenroll}
          name="preenroll"
          icon="user-shield"
          errorMessage={errors.preenroll}
          touched={touched.preenroll}
          label="I’ve given this information to the customer by brochure or verbally."
          />

        <div className="field is-grouped">
          <div className="control">
            <button
              className="button is-link"
              type="submit"
              disabled={isSubmitting || !values.preenroll}
              >
              Continue to Customer Enrollment
            </button>
          </div>
        </div>
      </form>
    </>
  );
};
