import React from "react";
import cn from "classnames";

export const Checkbox = ({
  onChange = () => {},
  onBlur = () => {},
  touched = false,
  value = "",
  label = "",
  name = "",
  icon = "",
  successMessage = "",
  errorMessage = false,
}) => {
  return (
    <label className="checkbox" style={{ marginBottom: "15px" }}>
      <input
        type="checkbox"
        name={name}
        className={cn("checkbox", {
          "is-success": touched && !errorMessage,
          "is-danger": touched && errorMessage,
        })}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        checked={value}
      />
      <span style={{ marginLeft: "5px" }}>{label}</span>
    </label>
  );
};
