import React from "react";
import { useFormik } from "formik";

import { Input } from "./Input";
import { validate } from "../utils/validate";
import { redirect, getRedirectUrl } from "../utils/redirect";
import { UrlOutput } from "./UrlOutput";

export const homePlusRedirect = formData => {
  const url = getRedirectUrl(formData, "https://enroll.asurion.com/home-plus-affiliates/customer-info")
  return redirect(url);
}
export const ISPPilotForm = () => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
  } = useFormik({
    initialValues: {
      referrerCode: "",
      agentId: "",
      storeCode: null,
      utm_campaign: "",
      vendorData: null
    },
    validate,
  });

  const [appliancePlusUrl, setAppliancePlusUrl] = React.useState()

  const generateAppliancePlusUrl = formData => {
    const url = getRedirectUrl(formData, "https://www.signup.asurion.com/appliance-plus/asurion/affiliate/personal-info")
    setAppliancePlusUrl(url);
  }

  return (
    <>
      <h1 className="title">Appliance+</h1>
      <p className="subtitle">Independent Service Provider Url Generator</p>
      <form>
        <Input
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.referrerCode}
          label="Referrer Code"
          name="referrerCode"
          icon="tags"
          errorMessage={errors.referrerCode}
          touched={touched.referrerCode}
        />

        <Input
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.agentId}
          label="Agent Id"
          name="agentId"
          icon="user-shield"
          errorMessage={errors.agentId}
          touched={touched.agentId}
        />
        <Input
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.storeCode}
          label="Store Code"
          name="storeCode"
          icon="store"
          errorMessage={errors.storeCode}
          touched={touched.storeCode}
        />
        <Input
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.utm_campaign}
          label="UTM Campaign"
          name="utm_campaign"
          icon="folder"
          errorMessage={errors.utm_campaign}
          touched={touched.utm_campaign}
        />
        <div className="field is-grouped" style={{display:"flex",flexDirection:"column"}}>
          <div className="control">
            <button
              className="button is-link"
              onClick={(e) => {
                e.preventDefault()
                generateAppliancePlusUrl(values)
              }}
            >
              Generate Unique URL
            </button>
          </div>
        </div>
      </form>
      <UrlOutput value={appliancePlusUrl} />
    </>
  );
};

export default ISPPilotForm;
