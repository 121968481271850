import React from "react";
import cn from "classnames";

import { Icon } from "./Icon";

export const Input = ({
  onChange = () => {},
  onBlur = () => {},
  touched = false,
  value = "",
  label = "",
  name = "",
  icon = "",
  successMessage = "",
  errorMessage = false
}) => {
  return (
    <div className="field">
      <label className="label">{label}</label>
      <div className="control has-icons-left has-icons-right">
        <input
          name={name}
          className={cn("input", {
            "is-success": touched && !errorMessage,
            "is-danger": touched && errorMessage
          })}
          type="text"
          value={value}
          onChange={onChange}
          onBlur={onBlur}
        />
        <Icon className={icon} />
        <Icon
          position="is-right"
          className={cn({
            spinner: !touched && !errorMessage,
            check: touched && !errorMessage,
            "exclamation-triangle": touched && errorMessage
          })}
        />
      </div>
      {touched && successMessage && (
        <p className="help is-success">{successMessage}</p>
      )}
      {touched && errorMessage && (
        <p className="help is-danger">{errorMessage}</p>
      )}
    </div>
  );
};
