import React from "react";

const PreEnrollmentInformation = () => {
  return (
    <div class="content">
      <h6>Pre-enrollment Information</h6>
      <ul>
        <li>Asurion Home+ is $24.99+tax per month</li>
        <li>
          The plan will continue to renew each month unless canceled and will be
          billed to your payment card.
        </li>
        <li>
          You may cancel at any time for no additional charge. If cancelled, a
          pro-rata refund of the service contract coverage may be provided as
          described in the terms and conditions.
        </li>
        <li>
          The plan begins on day 1 with access to technical support, and device
          protection coverage begins on day 31 of enrollment. Any pre-existing
          conditions or breakdowns that occur before day 31 are not covered. o
          Device coverage includes mechanical and electrical breakdown on
          eligible devices and Accidental Damage from Handling for certain
          portable devices.
        </li>
        <li>
          The plan provides up to $5,000 in coverage every 12 months and $2,000
          per approved claim.
        </li>
        <li>
          Non-original parts may be used for repair, and a replacement product
          may be a new, refurbished, or remanufactured product.
        </li>
        <li>
          A service fee of $0, $49, or $99 plus tax will be charged for each
          approved claim.
        </li>
        <li>
          Limitations and exclusions apply. Please see the Terms and Conditions
          for complete program details, including a binding individual
          arbitration provision applicable to this plan (unless state exceptions
          apply).
        </li>
      </ul>
    </div>
  );
};

export default PreEnrollmentInformation;
