import React from "react";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { AffiliateLinkForm } from "./components/Form";
import { ViasatLinkForm } from "./components/ViasatForm";
import { UnifiedFieldServicesForm } from "./components/UnifiedFieldServicesForm";
import { UBIFForm } from './components/UBIFForm';
import {ISPPilotForm} from "./components/ISPPilot";

const App = () => {
  return (
    <>
    <Router>
      <section className="section">
        <div className="container">
          <Routes>
            <Route path="/viasat" element={<ViasatLinkForm />} />
            <Route path="/ufs" element={<UnifiedFieldServicesForm />} />  
            <Route path="/ubif" element={<UBIFForm />} />
            <Route path="/isp-pilot" element={<ISPPilotForm />} />
            <Route path="/"element={<AffiliateLinkForm />} />
          </Routes>
        </div>
      </section>
    </Router>
    <section className="footer" style={{backgroundColor: 'white'}}>
      <div className="container" style={{display: 'flex', flexDirection: 'row'}}>
        <div>
          <a href="https://www.asurion.com/privacy-notice/" title="Privacy Notice">Privacy Notice</a>
        </div>
        <div>
          {/* OneTrust Cookies Settings button start --> */}
          <button id="ot-sdk-btn" className="ot-sdk-show-settings" style={{border: 'none', backgroundColor: 'white', color: 'black'}}>Cookie Settings</button>
          {/* <!-- OneTrust Cookies Settings button end --> */}
        </div>
      </div>
    </section>
    </>
  );
};

export default App;
