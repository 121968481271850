import React from "react";
import cn from "classnames";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faTags,
  faSpinner,
  faExclamationTriangle,
  faStore,
  faHandshake,
  faUserShield,
  faCheck,
  faFolder,
  faLink
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

library.add(
  faTags,
  faSpinner,
  faExclamationTriangle,
  faStore,
  faHandshake,
  faUserShield,
  faCheck,
  faFolder,
  faLink
);

export const Icon = ({ position = "is-left", className = "" }) => {
  if (!className) {
    return null;
  }

  return (
    <span
      className={cn("icon is-small", {
        [position]: true
      })}
    >
      <FontAwesomeIcon icon={className} />
    </span>
  );
};
